<template lang="html">
<base-modal :show-modal="showTerms" :modal-size="modalDevice" @close-modal="closeModal">
  <template v-slot:title>
    Terms of Service
  </template>
  <template v-slot:body>
    <fieldset class="p-2 space-y-4">
      <strong class="text-lg grid grid-cols-1 gap-y-2">
        <p>Date Last Modified: October 31, 2022</p>

        <p class="underline">IMPORTANT NOTES:</p>

        <p>THIS WEBSITE, THE OFFERED APPLICATIONS (“APPS”), AND THE RELATED SERVICES PROVIDE ONLY ENTERTAINMENT-STYLE GAMING AND DO NOT INCLUDE ANY OPPORTUNITY TO WIN REAL MONEY, PRIZES, OR ANY ITEMS HAVING MONETARY VALUE BASED ON THE OUTCOME OF THE GAMES OR FEATURES. NO ACTUAL MONEY OR ANYTHING OF VALUE CAN BE WON PLAYING THESE GAMES, AND NO ACTUAL MONEY IS REQUIRED TO PLAY.</p>

        <p>THESE TERMS OF SERVICE CONTAIN A DISPUTE RESOLUTION SECTION INCLUDING A MANDATORY ARBITRATION PROVISION AND A CLASS ACTION WAIVER PROVISION, WHICH AFFECTS YOUR RIGHTS WITH RESPECT TO DISPUTES YOU MAY HAVE WITH KSG MOBILE.  IF YOU DO NOT WISH TO BE SUBJECT TO THESE PROVISIONS, YOU MAY OPT OUT OF THEM WITHIN THIRTY (30) DAYS BY FOLLOWING THE STEPS PROVIDED IN THE DISPUTE RESOLUTION SECTION BELOW.</p>

        <p>RESPONSIBLE GAMEPLAY IS IMPORTANT TO KSG MOBILE.  FOR MORE INFORMATION ABOUT RESPONSIBLE GAMEPLAY, INCLUDING STEPS YOU CAN TAKE IF YOU NEED A BREAK OR HELP, PLEASE VISIT THE <span class="text-blue-500 underline cursor-pointer" @click="responsibleModal">KSG MOBILE RESPONSIBLE GAMEPLAY INFORMATION PAGE</span>.</p>
      </strong>
      <br>
      <!-- <span class="text-blue-500 underline" @click="privacyModal">Privacy Policy</span> -->
      <ol class="list-decimal list-outside ml-4 grid gap-2 grid gap-2">
        <li>
          <p class="text-lg mb-2">Acceptance of Terms</p>

          <p class="mb-2">The Terms of Service (“Terms”) you are reading represent a legally binding agreement that governs your relationship with KSG Mobile, Inc. and its affiliates, parent companies, joint ventures and other corporate entities under common ownership (“KSG Mobile”) and your use of KSG Mobile’s social casino products and related services on either desktop, mobile or any other applicable devices (the “Service”).</p>

          <p class="mb-2">Your use of the Service is subject to these Terms. &nbsp;We ask that you read them carefully before accessing the Service. &nbsp;By accessing, in any manner, the Service you agree that you have read, understood, accept and agree to be bound by these Terms and the <span class="text-blue-500 underline cursor-pointer" @click="privacyModal">Privacy Policy</span>. &nbsp;If you do not accept the Terms and the Privacy Policy you are not authorized to use the Service and must cease using it immediately.</p>

          <p class="mb-2">KSG Mobile reserves the right, in its sole discretion, to change the Terms and/or the Privacy Policy at any time. &nbsp;Any changes made to the Terms and /or Privacy Policy will be effective from the date the changes are posted here or in the Privacy Policy. &nbsp;The date of the most recent change will be included in the Terms and/or Privacy Policy. &nbsp;Users of the Service are responsible for reviewing the Terms and Privacy Policy for any changes prior to accessing, using or downloading the Service. &nbsp;Your continued use of the Service after any change constitutes your acceptance of the change. &nbsp;If you do not understand, accept and agree to be bound by any changes made to the Terms and/or Privacy Policy you are no longer authorized to use the Service and you must cease using the Service immediately.</p>

          <p class="mb-2">If you access the Service from a social network (e.g. Facebook) or download the product from a mobile app distribution platform (e.g. Apple’s App Store or Google Play) you are required to comply with the Terms of Service of the social network and/or the mobile app distribution platform in addition to KSG Mobile’s Terms.</p>

          <p class="mb-2">You furthermore agree to be bound by any application, forum, game specific or contest rule published by KSG Mobile from time to time.</p>
        </li>

        <li>
          <p class="text-lg mb-2">No Requirement to Purchase</p>

          <p class="mb-2">The games and features included in the Service are free to play and do not require any purchase of any kind. &nbsp;Virtual Currency (defined below) is provided for free upon your initial use of the Service and additional free Virtual Currency is provided at recurring time intervals. &nbsp;If you exhaust your supply of Virtual Currency, you may continue to play at least some of the games included in the Service at a minimum bet level, you may wait until additional free Virtual Currency is available, or you may elect to purchase additional Virtual Currency.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Age</p>

          <p class="mb-2">You may not use the Service if you are under 18 years of age (or the legal age of majority in your jurisdiction, whichever is greater). &nbsp;KSG Mobile reserves the right to request proof of age at any time and may terminate your Account (as that term is later defined) immediately if it has reasonable belief that you are not legally permitted to use the Service.</p>
        </li>

        <li>
          <p class="text-lg mb-2">User Accounts and Security</p>

          <p class="mb-2">Your use of the Service may require an account identifying you as a user (an “Account”). &nbsp;When you create an Account, you will be asked to provide certain information which will be subject to our Privacy Policy.</p>

          <p class="mb-2">The following rules govern the security of your Account and related credentials, which for greater certainty includes the login information of any social networking account you use to connect to the Service (e.g. Facebook), the login information for any mobile services through which you access the Service (e.g. an Apple ID and password) or any email / password you may add to your KSG Mobile Account (collectively, the “Login Information”):</p>

          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>You are responsible for maintaining the confidentiality of your Login Information; </li>
            <li>You will not let anyone else access your Account;</li>
            <li>You must notify KSG Mobile immediately if you become aware of: (i) any unauthorized use of your Account, (ii) any other breach of security with respect to your Account or the Service provided through it;</li>
            <li>Furthermore, if you have any reason to believe your confidential Login Information is known to others you must also immediately change your Login Information;</li>
            <li>You will provide assistance to KSG Mobile, as KSG Mobile may request, to stop or remedy any breach of security related to your Account;</li>
            <li>You are responsible for all activities that occur in your Account, whether or not such actions were taken by you or whether they were authorized or unauthorized;</li>
            <li>You commit to taking all reasonable steps to prevent unauthorized access to your Account;</li>
            <li>You commit to preventing any use of your Account by persons under the age of 18 (or under the legal age of majority in their jurisdiction, whichever is greater);</li>
            <li>You accept full responsibility for any unauthorized use of the Service by minors and accept responsibility for any charges made on your Account;</li>
            <li>You will not have more than one Account on any single platform used to distribute the Service (e.g. Facebook, Apple App Store, Google Play Store);</li>
            <li>If you have previously had your Account terminated or suspended by KSG Mobile, you are prohibited from creating another Account; and</li>
            <li>You will not create an Account for anyone except yourself.</li>
          </ul>
          <br>

          <p class="mb-2">KSG Mobile has no obligation to investigate the authorization or source of anyone’s access to or use of the Service but may do so and may terminate Accounts if it suspects any breach of the above. &nbsp;KSG Mobile is not liable for any loss or damage arising from your failure to comply with the aforementioned obligations, or for activities it undertakes regarding Account security at its sole discretion.</p>
          <p class="mb-2">You furthermore warrant and represent that all the information you provide to KSG Mobile is current, complete and accurate.</p>
          <p class="mb-2">You agree it is your responsibility to ensure all Account information provided remains current. &nbsp;KSG Mobile is not liable for any loss or damage caused by your failure to maintain current, complete and accurate information.</p>
          <p class="mb-2">You will be responsible for providing all equipment (computer, tablet, mobile device etc.) necessary to connect to the Service.</p>
          <p class="mb-2">You will be responsible for paying any fees, including internet connection fees, mobile fees for data usage or mobile roaming fees for accessing and using the Service.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Account Deletion</p>

          <p class="mb-2">To request your account to be deleted, you can go to “Settings” under the three bars menu on the upper-right hand corner of the casino lobby. &nbsp;Click “Delete Account” and follow the steps listed.</p>

          <p class="mb-2">Alternatively, please send an email to <a href="mailto:support@ksg.com" target="_top" class="text-blue-500 underline">support@ksg.com</a> and they will be able to assist you with your account deletion request.</p>
        </li>

        <li>
          <p class="text-lg mb-2">License</p>

          <p class="mb-2">Subject to your agreement and compliance with these Terms and the Privacy Policy, KSG Mobile grants you a non-exclusive, non-transferrable, non-sublicensable, revocable, limited license to access and use the Service through a supported web browser or mobile device solely for your own non-commercial, entertainment purposes, provided that with respect to any materials obtained from the Service, you may not:</p>

          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>modify, copy, reproduce or create derivative works of such materials, except as explicitly provided;</li>
            <li>use the materials for any commercial purpose;</li>
            <li>distribute or transmit the materials;</li>
            <li>publicly display, publish or perform the materials (for any purpose, commercial or non-commercial);</li>
            <li>create derivative works from, transfer, or sell any materials;</li>
            <li>attempt to decompile or reverse engineer any software or database contained in or accessed through the Service;</li>
            <li>remove any copyright or other proprietary notations without our prior, written consent.</li>
          </ul>
          <br>

          <p class="mb-2">All rights not expressly granted to you are reserved by KSG Mobile and, if applicable, its licensors. &nbsp;KSG Mobile’s Terms do not grant you or any other party any right, title or interest in the Service or any content in the Service.</p>

          <p class="mb-2">You agree that your license to use the Service is limited by KSG Mobile’s Terms. &nbsp;If the Service, or any part thereof, is determined to be illegal under the laws of the country in which you reside, you are not granted any license to use the Service and you must refrain from using the Service.</p>
        </li>

        <li>
          <p class="text-lg mb-2">User Content</p>

          <p class="mb-2">User generated content (“User Content) is any communications, messages, posts, comments, chats, images, sounds, and all the material, data, text, graphics, photographs, videos, location information, or any other content including game records, and their selection and arrangement, uploaded or transmitted by users (including you) through the Service, or any other Communication Channel (as defined below) as part of the Service to KSG Mobile, the public or any user.</p>

          <p class="mb-2">By submitting, transmitting, posting, uploading, or otherwise providing any User Content in connection with the Service, you are granting KSG Mobile and its affiliates a &nbsp;perpetual and irrevocable, worldwide, fully paid-up and royalty free, non-exclusive, unrestricted, unconditional, unlimited license, including the right to sublicense, transfer and assign to third parties, and right to copy, print, host, reproduce, fix, adapt, modify, improve, retitle, translate, reformat, archive, store, cache or otherwise exploit in any manner, create derivative works from, manufacture, introduce into circulation, commercialize, publish, distribute, disclose, sell, resell, license, sublicense, transfer, rent, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way now known or in the future discovered, in any media, at any time, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including advertising, marketing and promotions thereof. No credit, approval or compensation is due to you for any such use of the User Content you may submit. &nbsp;To the extent permitted by applicable laws, you hereby waive any moral rights you may have in any User Content (like the right to be identified as the author of the User Content or the right to object to a certain use of that User Content).</p>

          <p class="mb-2">KSG Mobile reserves the right to limit the storage capacity of User Content that you post on, through or in connection with the Service.</p>

          <p class="mb-2">You further represent and warrant that you own or otherwise control any and all rights in and to the User Content and that public posting of the User Content by KSG Mobile will not infringe or violate the rights of any third party in any manner.</p>

          <p class="mb-2">You agree that you are solely responsible for any User Content posted in connection with the Service and that you may be held liable for any User Content that you upload, post or otherwise transmit via the Service. &nbsp;KSG Mobile shall bear no responsibility for any User Content you upload and shall assume no responsibility for monitoring the Service for inappropriate or illegal content or conduct.</p>

          <p class="mb-2">With respect to any User Content posted by other users, such users are solely responsible for the User Content they post, including, without limitation, the reliability, accuracy, and truthfulness of any such User Content.</p>

          <p class="mb-2">You agree that the User Content you transmit or submit through the Service shall not include any personal identification, such as personal names, email addresses, or other indicia identifying any other person, including, without limitation, celebrities and/or other public or private figures, living or dead, or that is invasive of a person's privacy.</p>

          <p class="mb-2">Further, your User Content shall not include any of the following:</p>
          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>comments or other materials that are sexually oriented, explicit or suggestive or exploit people in a sexual or violent manner;</li>
            <li>comments or other materials that are violent or derogatory of any ethnic, racial, gender or religious group;</li>
            <li>comments or other materials that harass or advocate the harassment of another person or group;</li>
            <li>comments or other materials that promote the illegal use of alcohol, drugs, or tobacco, firearms/weapons or promotes any activities that may be construed as illegal;</li>
            <li>comments or other materials that are false or misleading or promote illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;</li>
            <li>comments or other materials that infringe any party's trademark, trade secret, copyright or other proprietary rights;</li>
            <li>comments or other materials that promote other products or services.</li>
          </ul>
          <br>

          <p class="mb-2">You acknowledge that we are not responsible for any User Content posted in connection with any portion of the Service. &nbsp;We are merely providing access to the Service and User Content as a service to our users to be used in accordance with these Terms. &nbsp;With respect to any User Content posted by other users, such users are solely responsible for the User Content they post, including, without limitation, the reliability, accuracy, and truthfulness of any such User Content. &nbsp;Similarly, we have no control over whether such User Content is of a nature that other users might find offensive, distasteful, or otherwise unacceptable and, accordingly, we expressly disclaim any responsibility for any User Content. &nbsp;Just as when you view content in any other setting, you should exercise appropriate discretion, good judgment, and caution in accessing User Content in the Service and in taking any actions based upon such User Content. &nbsp;Accordingly, you will bear all risks associated with any such User Content that you access or use.</p>

          <p class="mb-2">KSG Mobile reserves the right (but at no time is obligated to), in its sole discretion, monitor, remove, block, edit, move, disable or permanently delete User Content with or without notice for any reason whatsoever. &nbsp;You hereby agree that, to the maximum extent permitted by applicable law, KSG Mobile shall at no time be liable for the removal, modification, blocking, moving or deletion of User Content, and KSG Mobile disclaims any and all responsibility for User Content.</p>

          <p class="mb-2">If you are aware of any User Content posted in connection with the Service which violates these Terms please contact us at support@ksgmobileinc.com. &nbsp;Please provide as much detail as possible, including a copy of the underlying material, the location where we may find it, and the reason such User Content should be removed. &nbsp;Please note that filing a complaint will not guarantee its removal. &nbsp;We only will remove User Content if we believe the measure is necessary, in our sole discretion, or if we are required to do so by a court order enforceable against us. &nbsp;To the extent any notice is based on an alleged copyright violation, please follow the instructions set forth in the section entitled "Copyright Notices." Although we may attempt to monitor User Content, in no event do we assume any obligation to do so or liability for failing to either monitor the Service or remove specific User Content.</p>

          <p class="mb-2">KSG Mobile reserves the right to terminate the Account of any user who violates the Terms related to User Content.</p>

          <p class="mb-2">You agree to indemnify and save KSG Mobile harmless from and against any liabilities, actions, proceedings, claims, causes of action, demands, debts, losses, damages, charges and costs, including reasonable legal costs, any amount paid to settle any action or to satisfy a judgment and expenses of any kind and character whatsoever incurred by us relating to or arising from your User Content, including instances where your User Content (a) infringes any third party intellectual property rights, (b) is inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful, or (c) otherwise violates these Terms.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Communication Channels</p>

          <p class="mb-2">The Service may provide communication channels such as, but not limited to, fan pages, forums, communities, or chat areas ("Communication Channels") designed to enable you to communicate with other Service users and post User Content, including your feedback, questions, suggestions, ideas, submissions, observations and comments on designated topics.</p>

          <p class="mb-2">KSG Mobile is under no obligation to monitor these communication channels but may do so and reserves the right to review materials posted to the Communication Channels and to remove any materials, at any time, with or without notice for any reason, at its sole discretion. &nbsp;KSG Mobile may also terminate or suspend your access to any Communication Channels at any time, without notice, for any reason.</p>

          <p class="mb-2">You acknowledge that chats, postings, or materials posted by users on the Communication Channels are neither endorsed nor controlled by KSG Mobile, and these communications should not be considered reviewed or approved by KSG Mobile.</p>

          <p class="mb-2">You will be solely responsible for your activities within the Communication Channels and under no circumstances will KSG Mobile be liable for any activity within the Communication Channels.</p>

          <p class="mb-2">You agree that all your communications within the Communication Channels are public, and you have no expectation of privacy regarding your use of the Communication Channels.</p>

          <p class="mb-2">You agree that KSG Mobile may use, sell, exploit and disclose the comments, feedback, suggestions, concepts, ideas, know-how or techniques contained in any communications you provide in any manner, for any purpose whatsoever, commercial or otherwise, without restriction, without attribution and without compensation to you.</p>

          <p class="mb-2">KSG Mobile is not responsible for information that you choose to share on the Communication Channels, or for the actions of other users.</p>

          <p class="mb-2">Your information, and the contents of all of your online communications, including without limitation chat text, voice communications, IP addresses and your personal information (subject to the terms of our Privacy Policy) may be disclosed:</p>

          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>when we have a good faith belief that we are required to disclose the information in response to legal process (for example, a court order, search warrant or subpoena);</li>
            <li>to satisfy any applicable laws or regulations;</li>
            <li>where we believe that the Service is being used in the commission of a crime, including to report such criminal activity or to exchange information with other companies and organizations for the purposes of fraud protection and credit risk reduction;</li>
            <li>when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally; and</li>
            <li>in order to protect the rights or property of KSG Mobile, including to enforce our Terms.</li>
          </ul>
          <br>

          <p class="mb-2">By entering into these Terms, you hereby provide your irrevocable consent to such monitoring, recording access and disclosure.</p>

          <p class="mb-2">KSG Mobile is not responsible for information that you choose to share on the Communication Channels, or for the actions of other users, including without limitations, member’s misuse or misappropriation of any content or information you post in any Communication Channels.</p>

          <p class="mb-2">You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service. &nbsp;KSG Mobile reserves the right, but has no obligation, to become involved in any way with these disputes. &nbsp;If you have a dispute with one or more users, you release and hereby agree to indemnify KSG Mobile (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes, including damages for loss of profits, goodwill, use or data.</p>

          <p class="mb-2">In order to encourage users to participate in our game, we may publish non-personally identifying user data relating to the game according to various categories of users and statistics, as set upon our sole discretion. &nbsp;Such user data may be accessible to all users of the game. &nbsp;Personally identifying user data will always be dealt with in accordance with the terms of our Privacy Policy.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Virtual Coins and Virtual Goods</p>

          <p class="mb-2">The Service may include an opportunity to earn, purchase or otherwise acquire virtual, in-game currency ("Virtual Coins"), including but not limited to coins, diamonds, points, VIP Points, credits, bonuses or chips all for use in the Service. &nbsp;The Service may also include virtual, in-game items (“Virtual Goods”), including but not limited to icons, badges, pet xp, pet food, pet accessories, pet toys, pet tricks, pet perks, pet powerups, bingo balls, bingo powerups, digital collections and access to certain game content or game features. &nbsp;In these Terms, Virtual Coins and Virtual Goods are referred to collectively as “Virtual Items”.</p>

          <p class="mb-2">Virtual Items may only be obtained or purchased using “real world” money if you are legally permitted to obtain or purchase such Virtual Items in your country, province, jurisdiction and/or state of residence.  Further, Virtual Items may only be held by legal residents of countries where access to and use of the Service is legally permitted.</p>

          <p class="mb-2">Virtual Items can never be redeemed or cashed out for “real world” money, goods, or any other item of monetary value from KSG Mobile or any other party unless otherwise expressly authorized by KSG Mobile in the Terms.</p>

          <p class="mb-2">Other than a limited, personal, revocable, non-transferable, non-sublicensable license to use the Virtual Items with the Service during such time as we make such Virtual Items available within the Service, you have no right or title in or to any such Virtual Items appearing in or originating with the Service, or any other attributes associated with use of the Service or stored within the Service, whether “earned” in-game or “purchased” from KSG Mobile or otherwise acquired.</p>

          <p class="mb-2">Your purchase of Virtual Items is final and is not refundable, transferable or exchangeable, except in KSG Mobile’s sole discretion.</p>

          <p class="mb-2">KSG Mobile prohibits and does not recognize any purported transfers of Virtual Items effectuated outside of the Service, or the purported sale, gift or trade of anything that appears or originates in the Service, unless otherwise expressly authorized by KSG Mobile. &nbsp;Accordingly, you may not sublicense, trade, sell or attempt to sell in-game Virtual Items for "real" money, or exchange Virtual Items for value of any kind outside of a game, without KSG Mobile’s written permission. &nbsp;Doing so is a violation of these Terms and may result in termination of your Account and legal action taken against you, any such transfer or attempted transfer is prohibited and void.</p>

          <p class="mb-2">KSG Mobile retains the right to manage, regulate, control, modify, and eliminate Virtual Items at its sole discretion, and KSG Mobile shall have no liability to you or anyone for the exercise of such rights. &nbsp;Prices and availability of Virtual Items are subject to change without notice at KSG Mobile’s sole discretion. &nbsp;In addition to the foregoing, KSG Mobile may selectively remove or revoke Virtual Items associated with your Account upon its sole discretion.</p>

          <p class="mb-2">Virtual Items may only be purchased from KSG Mobile through means provided by KSG Mobile or otherwise expressly authorized by KSG Mobile.</p>

          <p class="mb-2">KSG Mobile does not recognize any purchases or transfers made outside of the Service on any other platform or e-commerce website and shall not be liable for any claims or damages caused to you with respect to Virtual Items purchased or obtained from third parties outside of the Service.</p>

          <p class="mb-2">We reserve the right to refuse your request to purchase or acquire Virtual Items for any reason at any time without liability to you.</p>

          <p class="mb-2">We reserve the right to revise the pricing for the Virtual Items at any time without liability to you.</p>

          <p class="mb-2">Virtual Items purchased from KSG Mobile on other platforms such as, but not limited to, social networks (e.g. Facebook) and / or a mobile app distribution platforms (e.g. Apple’s App Store or Google Play) require you to comply with the Terms of Service of those platforms in addition to KSG Mobile’s Terms. &nbsp;KSG Mobile does not control how you can pay on those platforms and shall not be liable for processing the payment by such third parties. &nbsp;Please review those platforms’ terms of service for additional information.</p>

          <p class="mb-2">Your order for Virtual Items will represent an offer to us to obtain a limited license for the relevant Virtual Items which will be accepted by us when we make the Virtual Items available in your Account for you to use in our games or debit your credit card or other Account through which you paid, whichever comes first.</p>

          <p class="mb-2">When purchasing Virtual Items you agree to pay us the applicable charges for your purchase, including applicable taxes incurred by you or anyone using an Account registered to you, using a valid charge card or other payment method we may accept in accordance with the billing terms and prices in effect at the time the fee or charge becomes payable.</p>

          <p class="mb-2">All sales of Virtual Items are final. &nbsp;No refunds will be given, except in KSG Mobile’s sole and absolute discretion.</p>

          <p class="mb-2">You agree to notify us about any billing problems or discrepancies within 30 days after they first appear on your account statement. &nbsp;If you do not bring them to our attention within 30 days, you agree that you waive your right to dispute such problems or discrepancies.</p>

          <p class="mb-2">You are responsible for and agree to reimburse us for all reversals, charge-backs, claims, fees, fines, penalties and other liability incurred by us (including costs and related expenses) that were caused by or arising out of payments that you authorized or accepted.</p>

          <p class="mb-2">You acknowledge and agree that KSG Mobile shall have no liability for any loss of Virtual Items whether due to (i) any unauthorized third-party activity, including but not limited to hacking, phishing, password mining, social engineering, or (ii) technological or system error or failure.</p>

          <p class="mb-2">You understand that KSG Mobile may suspend or terminate your Account if for any reason a charge you authorize KSG Mobile to make to your credit card or other method of payment cannot be processed or is returned to KSG Mobile unpaid and, if such event occurs, you shall immediately remit to KSG Mobile payment for such charge through an alternative payment method.</p>

          <p class="mb-2">You acknowledge that you will not receive money or other compensation for unused Virtual Items if your Account is suspended or terminated, whether such termination or suspension was voluntary or involuntary, or whether you purchased Virtual Coins or Virtual Goods. &nbsp;All Virtual Items are forfeited if your Account is terminated or suspended for any reason, at KSG Mobile’s sole and absolute discretion, or if the Service is no longer available.</p>

          <p class="mb-2">If your Account, or a particular subscription for the Service associated with your Account, is terminated, suspended and/or if any Virtual Coins or Virtual Goods are selectively removed or revoked from your Account, no refund will be granted, no Virtual Items will be credited to you or converted to cash or other forms of reimbursement.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Rules of Conduct and Usage</p>

          <p class="mb-2">You represent and warrant that you have full right and authority to use the Service and to be bound by these Terms. &nbsp;You further agree that your use of the Service shall be lawful and that you will comply with the usage rules.</p>

          <p class="mb-2">You acknowledge that the Service is intended for use by those 18 or older (or over the legal age of majority in their jurisdiction, whichever is greater) for amusement purposes only and only where it is legal for you to use the Service.</p>

          <p class="mb-2">You agree that you will comply fully with all applicable laws, regulations, statutes, ordinances, and KSG Mobile’s Terms.</p>

          <p class="mb-2">You undertake that you shall not defraud, or attempt to defraud, KSG Mobile or other users, and that you shall not act in bad faith in your use of the Service. &nbsp;If KSG Mobile determines that you do act in bad faith in violation of the KSG Mobile’s Terms, or if KSG Mobile determines that your actions fall outside of reasonable community standards, KSG Mobile may, at its sole discretion, terminate your Account and prohibit you from using the Service.</p>

          <p class="mb-2">You agree that your use of the Service shall be lawful and that you will comply with these Rules of Conduct and Usage. &nbsp;In furtherance of the foregoing, and as an example and not as a limitation, you agree that you shall not:</p>

          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>Create an Account or access the Service if you are under the age of 18 (or the legal age of majority in your jurisdiction, whichever is greater);</li>
            <li>Upload, post, transmit or otherwise disseminate material or information that is vulgar, violent, indecent, obscene, pornographic, sexual contains nudity, excessive violence or that is, in a reasonable person's view, otherwise offensive or objectionable subject matter or that contains a link to such content;</li>
            <li>post, upload, reproduce, distribute or otherwise transmit any User Content that constitutes a criminal offence, or otherwise engages in or assists others to engage in any criminal offence;</li>
            <li>Libel, ridicule, defame, mock, disparage, stalk, intimidate, threaten, harass, harm, advocate, incite harassment, or abuse another person, group, KSG Mobile’s employee, including KSG Mobile’s customer service representatives, hatefully, racially, religiously, ethnically or in any other manner;</li>
            <li>Upload or transmit (or attempt to upload or transmit) files that contain viruses, Trojan horses, worms, time bombs, cancelbots, RATs, keyboard loggers, spyware, adware, or any other malicious or invasive code or program, corrupted files or data, or any other similar software or programs that may damage the operation of the Service or the computers of other users of the Service;</li>
            <li>Advertise, solicit or transmit any commercial advertisements or unauthorized communications through the Service, including chain letters, junk e-mail or repetitive messages and any materials that promote malware, spyware and downloadable items to anyone;</li>
            <li>Violate the contractual, personal, intellectual property or other rights of any party including by using, uploading, transmitting, distributing, or otherwise making available any information or material made available through the Service in any manner that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity);</li>
            <li>Create false personas, multiple identities, multiple user Accounts, set up an Account on behalf of someone other than yourself, use bots or other automated software programs to defraud or which otherwise violate the KSG Mobile’s Terms and/or the terms of service of any third-party applications or social networks through which the Service is accessed;</li>
            <li>Attempt to obtain or reveal passwords or other private information from other members including personally identifiable information (whether in text, image or video form), identification documents, financial information or any information that may be used to track, contact or impersonate another individual, including without limitation, other users and KSG Mobile’s employees;</li>
            <li>Upload or transmit (or attempt to upload or to transmit), without KSG Mobile’s express permission, any material that acts as a passive or active information collection or transmission mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies or other similar devices (sometimes referred to as "spyware", "passive collection mechanisms" or "pcms");</li>
            <li>Improperly use support channels to make false reports to KSG Mobile or use profane and abusive language in your communications with our support personnel;</li>
            <li>Use the Service in order to design, assist, develop, distribute, use, or publicly inform other members of "auto" software programs, "macro" software programs, "cheat utility" software programs or applications, hacks, mods or any other unauthorized third party software designed to allow the user to cheat or obtain an unintended advantage while using the Service or modify or interfere with the Service or any KSG Mobile game experience, including, without limitation, enabling unauthorized activity related to Users’ balances;</li>
            <li>Exploit, distribute or publicly inform other members of any game error, miscue, bug or technical malfunction which gives an unintended advantage, including but not limited to accessing one-time promotions more than once;</li>
            <li>Deal with Virtual Items in a manner that violates these Terms, including transferring Virtual Items to other individuals, parties, or entities, selling or re-selling Virtual Items or fraudulently obtaining or acquiring Virtual Items or other products or services;</li>
            <li>Rent, lease, sell, trade, gift, bequeath or otherwise transfer or share your Account to anyone without KSG Mobile’s written permission;</li>
            <li>Access or use an Account which has been rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the Account creator without KSG Mobile’s written permission;</li>
            <li>Engage in any fraudulent activity with respect to payment methods or advertiser tracking mechanisms;</li>
            <li>Use the Service, intentionally or unintentionally, in connection with any violation of any applicable laws or regulations, or encourage or promote the violation of any applicable law or regulation or any illegal activity including, but not limited to, defamation, invasion of privacy, identity theft, hacking, cracking or distribution of counterfeit software, or cheats or hacks for the Service;</li>
            <li>Attempt to use the Service on or through any service that is not authorized by KSG Mobile. &nbsp;Any such use is at your own risk and may subject you to additional or different terms. &nbsp;KSG Mobile takes no responsibility for your use of the Service through any service that is not authorized by it;</li>
            <li>Collect, harvest, post or communicate any person's real-world personal information or private information, including personally identifiable information (whether in text, image or video form), identification documents, or financial information through the Service;</li>
            <li>Attempt to interfere with, hack into or decipher any transmissions to or from the servers for the Service;</li>
            <li>Interfere with the ability of others to enjoy using the Service or any KSG Mobile game, including disrupt, overburden, assist or aid in the disruption or overburdening of any computer or server used to offer or support the Service or any KSG Mobile game environment, or take actions that interfere with or materially increase the cost to provide the Service for the enjoyment of all its users;</li>
            <li>Engage in any act that KSG Mobile deems to be in conflict with the spirit or intent of the Service, including but not limited to circumventing or manipulating the KSG Mobile Terms, our game rules, game mechanics or policies;</li>
            <li>Without KSG Mobile’s express written consent, modify or cause to be modified any files, codes, or any other component which is part of the Service;</li>
            <li>Institute, assist or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other person’s use or enjoyment of the Service;</li>
            <li>Attempt to gain unauthorized access to the Service, Accounts registered to others or to the computers, servers, or networks connected to the Service by any means other than the user interface provided by KSG Mobile, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Service;</li>
            <li>Use the Service or any part thereof for performing in-game services, such as power-leveling and item collection services, in exchange for payment outside the Service;</li>
            <li>Interfere or attempt to interfere with the proper functioning of the Service or connect to or use the Service in any way not expressly permitted by these Terms;</li>
            <li>Use any unauthorized third-party software that accesses, intercepts, "mines", or otherwise collects information from or through the Service or that is in transit from or to the Service, including, without limitation, any software that reads areas of RAM or streams of network traffic used by the Service to store information about KSG Mobile game characters, elements, or environment. &nbsp;KSG Mobile may, at its sole and absolute discretion, allow the use of certain third-party user interfaces;</li>
            <li>Intercept, examine or otherwise observe any proprietary communications protocol used by a client, a server, or the Service, whether through the use of a network analyzer, packet sniffer or other device;</li>
            <li>Make any automated use of the system, or take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>Bypass any robot exclusion headers or other measures we employ to restrict access to the Service or use any software, technology, or device to send content or messages, scrape, spider, or crawl the Service, or harvest or manipulate data;</li>
            <li>Use, facilitate, create, or maintain any unauthorized connection to the Service, including without limitation: (a) any connection to any unauthorized server that emulates, or attempts to emulate any part of the Service; or (b) any connection using programs, tools, or software not expressly approved by KSG Mobile;</li>
            <li>Except where permitted by law or relevant open source licenses, reverse engineer, decompile, disassemble, decipher, modify or otherwise attempt to derive the object code or source code for any underlying software or other intellectual property used to provide the Service, or to obtain any information from the Service using any method not expressly permitted by KSG Mobile;</li>
            <li>Copy, modify or distribute rights or content from any KSG Mobile site or game, or KSG Mobile copyrights or trademarks or use any method to copy or distribute the content of the Service except as specifically allowed in these Terms;</li>
            <li>Solicit or attempt to solicit personal information from other users of the Service;</li>
            <li>Use of capital letters and spamming (continuous posting of repetitive text) that interferes with any party’s uninterrupted use and enjoyment of the Service and User Content or modifies, impairs, disrupts, alters or interferes with the use, features, functions, operation or maintenance of the Service and User Content;</li>
            <li>Copy or adapt the Service’s software or code;</li>
            <li>Use any automatic machine to operate KSG Mobile Client Software or use any other KSG Mobile software, other than the KSG Mobile Client Software;</li>
          </ul>
          <br>

          <p class="mb-2">Any use of the Service in violation of these Rules of Conduct and Usage is strictly prohibited, can result in the immediate revocation of your limited license granted hereunder and termination of your Account; and may subject you to criminal and financial liability for violation of criminal and civil laws.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Intellectual Property (IP)</p>

          <p class="mb-2">KSG Mobile and its licensors retain all rights in the Service materials, including, but not limited to, any games,  applications, software, titles, computer code, themes, objects, characters, character names, animations, stories, dialogue, catch phrases, concepts, artwork, designs, graphics, fonts, texts, information, pictures, video, sound, music, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, personalized avatar,  recordings of games played and other files, and their selection and arrangement, and any other work (collectively,  the “Content”). &nbsp;The Content is protected by applicable copyright, trade dress, patent, and trademark laws, international conventions, and other laws protecting intellectual property and related proprietary rights.</p>

          <p class="mb-2">KSG Mobile reserves all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with the Service and KSG Mobile’s names, logos, trademarks, brands or service marks (“Trademarks”) used with the Service and are owned or licensed by KSG Mobile.</p>

          <p class="mb-2">You may only use the Content in connection with your use of the Service for personal, non-commercial, entertainment purposes. The Content may not be used by you in any other manner, or for any purpose, without KSG Mobile’s express written permission and / or the consent of any third party we deem necessary, except as provided for herein. &nbsp;Any unauthorized use by you of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and other applicable regulations and statutes.</p>

          <p class="mb-2">KSG Mobile, or its licensors and affiliates (as applicable) own all right, title, and interest, including copyrights, trademarks and other intellectual property rights, in and to all the Content. &nbsp;You hereby acknowledge that you do not acquire any ownership rights by using the Service or by accessing any of the Content.</p>

          <p class="mb-2">You shall not, nor shall you cause any other party to modify, decompile, disassemble, reverse engineer, reverse assemble or otherwise attempt to discover any source code, copy, transfer, create derivative works from, assign, rent, sub-license, distribute, reproduce framed, republish, scrape, download, display, transmit, post, grant a security interest, lease or sell in any form or by any means, in whole or in part, use for any purpose other than for using the Service pursuant to these Terms or otherwise exploit any of the Content without KSG Mobile’s explicit, prior written permission.</p>

          <p class="mb-2">Any communications or materials you transmit to KSG Mobile by electronic mail or otherwise, including any data, questions, questionnaires, surveys, comments, suggestions, or the like, are and will be treated as, non-confidential and non-proprietary. &nbsp;Anything you transmit, or post may be used by KSG Mobile or its affiliates for any purpose, including, but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. &nbsp;Furthermore, KSG Mobile is free to use any ideas, concepts, know-how or techniques contained in any communication you send to KSG Mobile for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products using such information, without any compensation to you.</p>

          <p class="mb-2">Some content accessed or available through the Service or the Internet may be owned by parties other than you or us (collectively, “Third Party Content”) and may be protected by applicable copyrights, trade-marks, patents, trade secrets or other proprietary rights and laws. &nbsp;Nothing in your use of the Service or these Terms grants you any right, title or interest in or to this Third Party Content except for the limited right to use the Service as set out herein, and no use of Third Party Content implies any endorsement by the third party in KSG Mobile or the Service or any endorsement by KSG Mobile or the Service of such third party, nor any relationship between them.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Copyright Notices</p>

          <p class="mb-2">If you believe that any content on our Service infringes upon any copyright or other intellectual property right that you own or control, you may send a written notification of such infringement to our designated agent:</p>

          <p class="mb-2">By mail:</p>

          <p class="mb-2">
            KSG Mobile, Inc.<br>
            10275 Wayzata Boulevard, Suite 300<br>
            Minnetonka, MN 55305<br>
            USA
          </p>

          <p class="mb-2">
            By email:<br>
            <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline">support@ksgmobileinc.com</a>
          </p>

          <p class="mb-2">Your notification must be a written communication that includes the following:</p>
          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>a physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
            <li>a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
            <li>a description of where the material that you claim is infringing is located in the Services;</li>
            <li>your address, telephone number, and email address;</li>
            <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and</li>
            <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
          </ul>
          <br>

          <p class="mb-2">If you are a Canadian rights-holder, your written communication must also include the following, to the extent not already set out above:</p>
          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>the claimant’s name and address;</li>
            <li>the identity of the work to which the alleged infringement relates;</li>
            <li>the claimant’s interest or rights in respect of the work;</li>
            <li>the type of infringement claimed; and</li>
            <li>the electronic location data for which the alleged infringement relates; and</li>
            <li>the date and time of the alleged infringing activity.</li>
          </ul>
          <br>
        </li>

        <li>
          <p class="text-lg mb-2">Game Play</p>

          <p class="mb-2">You may not use the Service if you are under 18 years of age (or the legal age of majority in your jurisdiction, whichever is greater). &nbsp;KSG Mobile reserves the right to request proof of age at any time and may terminate your Account (as that term is later defined) immediately if it has reasonable belief that you are not legally permitted to use the Service.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Changes to the Service</p>

          <p class="mb-2">KSG Mobile may, in its sole discretion and without notice modify the Service, in whole or in part. &nbsp;Such modifications may include charging fees for use of the Service or waiving fees related to the Service.</p>

          <p class="mb-2">You acknowledge that changes to the Service might require KSG Mobile to perform remote updates. &nbsp;You grant KSG Mobile permission to perform such remote updates.</p>

          <p class="mb-2">KSG Mobile may, in its sole discretion and without notice, in whole or in part, suspend, limit or stop offering the Service and/or stop supporting the Service.</p>

          <p class="mb-2">In the event KSG Mobile, in part of in whole, modifies, suspends, limits, stops offering and/or stops supporting the Service you agree that any Virtual Items (defined herein) purchased, earned or otherwise acquired by you may be forfeited and KSG Mobile will have no liability to you or any other third party.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Rules for Sweepstakes, Contests and Promotions</p>

          <p class="mb-2">Any sweepstakes, contests, raffles, surveys, games or similar promotions made available through the Service may be governed by specific rules that are separate from and in addition to these Terms. &nbsp;By participating in any such sweepstakes, contest, raffle, survey, game, or similar promotion, you will become subject to those rules, which may vary from the Terms as set forth herein, and which are incorporated into these Terms. &nbsp;KSG Mobile urges you to read the applicable rules, which may be linked from the particular activity, and to review the <span class="text-blue-500 underline" @click="privacyModal">Privacy Policy</span>, which, in addition to these Terms, governs any information you submit in connection with such activities.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Account Restrictions / Account Termination</p>

          <p class="mb-2">KSG Mobile reserves the right, in its sole discretion, and without notice to:</p>

          <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
            <li>limit, suspend, terminate, modify or delete your Account;</li>
            <li>limit, delay, suspend, prevent or modify your access to any portion of the Service, including game Content, Virtual Items and features offered by the Service;</li>
            <li>terminate any Account that has not been active for 180 days.</li>
          </ul>

          <p class="mb-2">In the event your Account is terminated by KSG Mobile, for any reason, any Virtual Items (defined herein) purchased, earned or otherwise acquired by you will be forfeited and KSG Mobile will have no liability to you or any other third party.</p>

          <p class="mb-2">In the event that your Account is terminated, KSG Mobile may delete your Account and all related information and files without notice to you. You acknowledge and agree that KSG Mobile is under no obligation to provide any further access to such information or files and will have no liability to you or any other third party.</p>

          <p class="mb-2">In the event that your Account is restricted, limited or modified in any way you acknowledge and agree that KSG Mobile has no liability to you or any other third party.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Third Party Websites and Advertising</p>

          <p class="mb-2">You understand that the Service may feature advertisements from KSG Mobile and/or third parties. &nbsp;KSG Mobile may provide links in the Service to third party websites or vendors. &nbsp;Any charges or obligations you incur in your dealings with these third parties are your responsibility. &nbsp;KSG Mobile makes no representation or warranty regarding any content, goods and/or services provided by any third party even if linked to from our Service and will not be liable for any claim relating to any third-party content, goods and/or services.</p>

          <p class="mb-2">The linked sites are not under the control of KSG Mobile and may collect data or solicit personal information from you, or they may automatically collect information from you. &nbsp;When you use other companies’ services like these, the other company’s service may (or may not) ask you for permission to access your information and content. &nbsp;KSG Mobile is not responsible for their content, business practices or privacy policies, or for the collection, use, or disclosure of any information those sites may collect from you. &nbsp;Your relationship with that other company will control how it can use, store, and share your information. &nbsp;Further, the inclusion of any link does not imply endorsement by KSG Mobile of these linked sites. &nbsp;We are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with the linked site, unless specifically stated therein. &nbsp;By accessing, using or downloading the Service, you acknowledge and agree that KSG Mobile has not reviewed all the web sites linked to the Service. &nbsp;Your linking to any other off-site pages or other sites is at your own risk.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Limitation of Liability</p>

          <p class="mb-2">BY ACCESSING, USING OR DOWNLOADING THE SERVICE YOU ACKNOWLEDGE AND AGREE THAT YOUR USE IS AT YOUR OWN RISK AND THAT NONE OF THE PARTIES INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICE OR ANY OF KSG Mobile, ITS AFFILIATES, SUBISIDIARIES OR ANY OF THEIR EMPLOYEES, AGENTS OR CONTRACTORS (COLLECTIVELY "RELEASED PARTIES") ARE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, OR ANY OTHER LOSSES, COSTS, OR EXPENSES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF DATA, LEGAL FEES, EXPERT FEES, COST OF PROCURING SUBSTITUTE SERVICES, LOST OPPORTUNITY, OR OTHER DISBURSEMENTS) WHICH MAY ARISE, DIRECTLY OR INDIRECTLY, THROUGH THE ACCESS TO, USE OF, RELIANCE ON ANY MATERIAL OR CONTENT ON THE SERVICE, OR BROWSING OF THE SERVICE OR THROUGH YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM THE SERVICE, EVEN IF KSG Mobile HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE FOREGOING, RELEASED PARTIES ASSUME NO RESPONSIBILITY, AND WILL NOT BE LIABLE, FOR ANY DAMAGES RELATING TO OR CAUSED BY ANY VIRUSES, BUGS, HUMAN ACTION OR INACTION OF ANY COMPUTER SYSTEM, PHONE LINE, HARDWARE, SOFTWARE OR PROGRAM MALFUNCTIONS, OR ANY OTHER ERRORS, FAILURES OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS ON ACCOUNT OF YOUR ACCESS TO OR USE OF THE SERVICE. &nbsp;RELEASED PARTIES CANNOT AND DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, OR SECURE ACCESS TO THE SERVICES.</p>

          <p class="mb-2">In case of a malfunction or disruption of the Service that prevents you from completing a game in which you have begun to participate, KSG Mobile may allow users to participate again in a game until the participation is complete. &nbsp;KSG Mobile reserves the right to determine, in its sole discretion, whether it is responsible for any such malfunction or disruption. &nbsp;KSG Mobile also reserves the right to limit your game play or terminate your participation in the Service should KSG Mobile determine, in its sole discretion, that you have intentionally caused such a malfunction or disruption. &nbsp;KSG Mobile is not liable for any potential winnings or other lost opportunity from any unfinished game.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Release of Liability</p>

          <p class="mb-2">To the fullest extent permitted by law, you agree to release, discharge, defend, indemnify and hold Released Parties harmless from and against all claims, damages, losses, liability, costs and expenses (including without limitation attorney’s fees) arising out of (a) your use of, access to, or activities in connection with the Service or (b) any violation of these Terms by you or through your Account. This defense and indemnification obligation will survive these Terms and your use of the Service.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Disclaimer of Warranties</p>

          <p class="mb-2">THE SERVICE, IN WHOLE AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL CONTENT, AND USER CONTENT), ARE PROVIDED, TRANSMITTED, DISTRIBUTED, AND MADE AVAILABLE "AS IS" WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. &nbsp;WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY: (A) THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE; (B) THAT DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED; (C) THAT THE SERVICE WILL BE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS; (D) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND VALIDITY OF ANY INFORMATION OR MATERIALS IN CONNECTION WITH THE SERVICE; (E) THAT YOUR USE OF THE SERVICE WILL MEET YOUR REQUIREMENTS; OR (F) THAT TRANSMISSIONS OR DATA WILL BE SECURE.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Exceptions</p>

          <p class="mb-2">SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER, EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES, LIABILITIES AND DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. &nbsp;IN SUCH JURISDICTIONS, OUR LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Updates to the Site and Service; Maintenance</p>

          <p class="mb-2">You acknowledge and agree that KSG Mobile may update the Service with or without notifying you. &nbsp;KSG Mobile may require that you accept updates to the Service and you may also need to update third party software from time to time in order to receive the Service.</p>

          <p class="mb-2">KSG Mobile conducts maintenance work on its system from time to time. A portion, or sometimes all, of the features of the Service will not be available during maintenance periods.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Legality of Service Use</p>

          <p class="mb-2">You are subject to all laws of the geography in which you reside and from which you access the Service and are solely responsible for obeying those laws. &nbsp;You agree KSG Mobile cannot be held liable if laws applicable to you restrict or prohibit your participation. &nbsp;KSG Mobile makes no representations or warranties, implicit or explicit, as to your legal right to access or participate in the Service nor shall any person affiliated, or claiming affiliation, with KSG Mobile have authority to make any such representations or warranties. &nbsp;KSG Mobile reserves the right to monitor the location from which you access the Service and to block access from any jurisdiction in which participation is illegal or restricted.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Governing Law</p>

          <p class="mb-2">These Terms and any dispute arising out of or related to it or the Service shall be governed in all respects by the laws of the State of Minnesota, without regard to principles of conflict of laws and regardless of your location.  &nbsp;Except as provided for in the “Dispute Resolution” section below, the exclusive jurisdiction and venue of any action with respect to the subject matter of these Terms will be the state and federal courts located in the State of Minnesota and each of the parties hereto waives any objection to jurisdiction and venue in such courts.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Dispute Resolution</p>

          <p class="mb-2">PLEASE READ THIS SECTION INCLUDING THE BINDING ARBITRATION PROVISION AND CLASS WAIVER PROVISION CAREFULLY AS THEY REQUIRE YOU TO ARBITRATE ALL DISPUTES WITH KSG MOBILE AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF UNLESS YOU FOLLOW THE OPTIONAL OPT-OUT STEPS DESCRIBED BELOW WITHIN THIRTY (30) DAYS.</p>

          <p class="mb-2">THESE PROVISIONS GENERALLY PRECLUDE YOU FROM BRINGING, JOINING OR PARTICIPATING IN ANY PAST, CURRENT OR FUTURE CLASS, COLLECTIVE OR REPRESENTATIVE ACTION AGAINST KSG MOBILE, AND REQUIRES BOTH YOU AND KSG MOBILE TO RESOLVE ANY DISPUTES (EXCEPT AS PROVIDED FOR BELOW) VIA ARBITRATION RATHER THAN IN A COURT OF LAW (INCLUDING HAVING A JURY TRIAL).</p>

          <p class="mb-2">YOU AND KSG MOBILE AGREE THAT ANY CAUSE OF ACTION REGARDLESS OF FORM OR WHETHER BROUGHT IN ARBITRATION OR COURT (IF APPLICABLE), ARISING OUT OF OR RELATED TO THE SERVICE AND/OR KSG MOBILE’S TERMS MUST BE FILED WITHIN ONE (1) YEAR OF THE ACTION, OMISSION, EVENT OR OCCURRENCE GIVING RISE TO SUCH CLAIM. AFTER THE CAUSE OF ACTION ACCRUES.  OTHERWISE, SUCH CAUSE OF ACTION IS TIME-BARRED AND PROHIBITED.</p>

          <p class="underline mb-2">Pre-Arbitration Claim Resolution</p>

          <p class="mb-2">For all Disputes, whether pursued in court or arbitration, you must first give KSG Mobile an opportunity to resolve the Dispute. &nbsp;You must commence this process by mailing written notification to KSG Mobile Legal Department, 10275 Wayzata Blvd. Suite 300, Minnetonka, Minnesota 55305. &nbsp;That written notification must include (1) your name, (2) your address, (3) a written description of your claim, and (4) a description of the specific relief you seek. &nbsp;If KSG Mobile does not resolve the Dispute within 45 days after it receives your written notification, you may pursue your Dispute in arbitration. &nbsp;You may pursue your Dispute in a court only under the circumstances described below.</p>

          <p class="underline mb-2">Mandatory Arbitration Provision</p>

          <p class="mb-2">YOU AND KSG MOBILE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES, AS DEFINED BELOW, WHETHER PRESENTLY IN EXISTENCE OR BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS DISPUTE RESOLUTION PROVISION.</p>

          <p class="mb-2">For purposes of this Dispute Resolution section, a “Dispute” is any dispute, claim or controversy regarding any aspect of your relationship with KSG Mobile, whether arising out of or relating to your access to or use of any KSG Mobile Site or Application (App) (including all Services) or to the interpretation, construction, validity, enforceability, or breach of these Terms (including this Dispute Resolution section), except as provided below.</p>

          <p class="mb-2">You understand and agree that by entering into this agreement you and KSG Mobile are each waiving the right to a jury trial or a trial before a judge in a court or law or equity. &nbsp;If you opt-out of this Mandatory Arbitration Provision as provided for below, you and KSG Mobile may have a right or opportunity to bring Disputes in a court, before a judge or jury. By not opting out, those rights are waived, and any rights to appeal or to certain types of discovery, may be more limited or may also be waived.</p>

          <p class="underline mb-2">Exceptions to Arbitration</p>

          <p class="mb-2">You and KSG Mobile are NOT required to arbitrate any Dispute in which either party seeks equitable or other relief for the alleged unlawful use of copyrights, trademarks, tradenames, logos, patents or trade secrets.</p>

          <p class="underline mb-2">Class Action Waiver Provision</p>

          <p class="mb-2">Whether the Dispute is heard in arbitration or in court, you agree that you and KSG Mobile will not initiate or participate in a class action lawsuit, class arbitration, or other representative action or proceeding. &nbsp;In an arbitration, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a class or representative proceeding or claims (such as a class action, consolidated action or private attorney general action) unless both you and KSG Mobile specifically agree to do so following initiation of the arbitration. &nbsp;Neither you, nor any other user of the Service can be a class representative, class member, or otherwise participate in a class, consolidated, or representative proceeding without having complied with the opt-out requirements above</p>

          <p class="underline mb-2">Right to Opt-Out</p>

          <p class="mb-2">YOU MAY OPT OUT OF THE MANDITORY ARBITRATION PROVISION AND CLASS WAIVER PROVISION BY FOLLOWING THE STEPS BELOW WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the “Opt-Out Deadline”). &nbsp;IF YOU DO NOT OPT OUT BY THE OPT-OUT DEADLINE, THESE TERMS WILL APPLY RETROACTIVELY TO ALL CLAMIS YOU MAY HAVE POSSESED, WHETHER ASSERTED TO DATE OR NOT, AS WELL AS ANY CURRENT OR FUTURE CLAIMS ARISING FROM A DISPUTE AS SET OUT HEREIN.</p>

          <p class="mb-2">You may opt out of this Dispute Resolution and Arbitration Provision by mailing written notification to KSG Mobile, Attn: Dispute Resolution Opt Out, 10275 Wayzata Blvd. Suite 300, Minnetonka, Minnesota 55305. &nbsp;Your written notification must include (1) your name, (2) your address, and (3) a clear statement that you do not wish to resolve disputes with KSG Mobile through arbitration. &nbsp;Your decision to opt-out of this Dispute Resolution and Arbitration Provision will have no adverse effect on your relationship with KSG Mobile. &nbsp;Any opt-out request received after the Opt-Out Deadline will not be valid and you must pursue your Dispute in arbitration.</p>

          <p class="underline mb-2">Arbitration Procedures</p>

          <p class="mb-2">Arbitration is a form of private dispute resolution in which persons with a dispute waive their rights to file a lawsuit, to proceed in court and to a jury trial, and instead submit their disputes to a neutral third person (or arbitrator) for a binding decision. &nbsp;There is no judge or jury in arbitration, and court review of an arbitration decision or award is limited. &nbsp;The arbitrator must follow this agreement and can award similar damages and relief as a court.</p>

          <p class="mb-2"><strong>Arbitration Selection and Authority</strong>: If a Dispute is not resolved as provided above (“Pre-Arbitration Claim Resolution”) either you or KSG Mobile may initiate arbitration proceedings using either the American Arbitration Association (“AAA”), www.adr.org, or JAMS, www.jamsadr.com, which will arbitrate all Disputes. &nbsp;The arbitration shall be conducted before a single arbitrator and  be commenced as an individual arbitration, and shall in no event be commenced as a class arbitration. All issues shall be for the arbitrator to decide, including the scope of this Dispute Resolution and Arbitration Provision. For arbitration before AAA, for Disputes of less than $50,000, the AAA’s Supplementary Procedures for Consumer-Related Disputes will apply; for Disputes involving $50,000 or more, the AAA’s Commercial Arbitration Rules will apply. &nbsp;In either instance, the AAA’s Optional Rules For Emergency Measures Of Protection shall apply. &nbsp;For arbitration before JAMS, the JAMS Comprehensive Arbitration Rules & Procedures and the JAMS Recommended Arbitration Discovery Protocols For Domestic, Commercial Cases will apply. This Dispute Resolution Section governs in the event it conflicts with the applicable arbitration rules. &nbsp;Under no circumstances will class action procedures or rules apply to the arbitration. Because the Service and these Terms concern interstate commerce, the Federal Arbitration Act (“FAA”) governs the arbitrability of all Disputes. &nbsp;However, the arbitrator will apply applicable substantive law consistent with the FAA and the applicable statute of limitations or condition precedent to suit.</p>

          <p class="mb-2"><strong>Arbitration Award</strong>: The arbitrator may award, on an individual basis, any relief that would be available pursuant to applicable law, and will not have the power to award relief to, against, or for the benefit of any person who is not a party to the proceeding. &nbsp;The arbitrator will make any award in writing but need not provide a statement of reasons unless requested by a party. &nbsp;Such award will be final and binding on the parties, except for any right of appeal provided by the FAA, and may be entered in any court having jurisdiction over the parties for purposes of enforcement.</p>

          <p class="mb-2"><strong>Location of Arbitration</strong>: You and KSG Mobile may initiate arbitration in either Minneapolis, Minnesota. &nbsp;You may request to appear in such proceeding telephonically or over a video medium such as Zoom or Microsoft Teams if offered.</p>

          <p class="mb-2"><strong>Allocation of Arbitration Fees and Costs</strong>: If you assert a Dispute as an individual, you will only be required to pay arbitration fees of $250 in connection with any arbitration under this Dispute Resolution Section, and KSG Mobile will pay all other costs shared by AAA, JAMS or the arbitrator up to $5000. &nbsp;You are responsible for all additional fees and costs that you incur in the arbitration, including, but not limited to, attorneys or expert witnesses. &nbsp;Fees and costs may be awarded as provided pursuant to applicable law.</p>

          <p class="mb-2"><strong>Arbitration Confidentiality</strong>: You and KSG Mobile agree to maintain the confidential nature of any arbitration proceeding, including the existence of the arbitration, any confidential information learned about the other party, and the arbitration award, except as may be necessary in connection to a judicial challenge to the arbitration award or its enforcement, or unless otherwise required by law or judicial decision.</p>

          <p class="underline mb-2">Severability and Survival of Dispute Resolution</p>

          <p class="mb-2">If any clause within this Dispute Resolution Section is found to be illegal or unenforceable, that clause will be severed from this Dispute Resolution Section, and the remainder of this Dispute Resolution Section will be given full force and effect. &nbsp;This Dispute Resolution Section shall survive the termination of your service with KSG Mobile.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Entire Agreement</p>

          <p class="mb-2">These Terms, in connection with the Privacy Policy, constitute the entire agreement between you and KSG Mobile and cannot be modified by you. &nbsp;Nothing in this subsection will prevent KSG Mobile from modifying these Terms. &nbsp;The Terms may only be modified by KSG Mobile if expressly authorized by a duly appointed Officer or Director of KSG Mobile.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Assignment of Terms</p>

          <p class="mb-2">KSG Mobile may assign or delegate the Terms, in whole or in part, to any person or entity at any time with or without your consent. &nbsp;You may not assign or delegate any rights or obligations under the Terms without KSG Mobile’s prior written consent, and any unauthorized assignment and delegation by you is void and ineffective.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Enforcement of Performance</p>

          <p class="mb-2">The failure of KSG Mobile to require or enforce strict performance by you of any provision of the KSG Mobile’s Terms or failure to exercise any right under them shall not be construed as a waiver or relinquishment of KSG Mobile’s right to assert or rely upon any such provision or right in that or any other instance. &nbsp;The express waiver by KSG Mobile of any provision, condition, or requirement of KSG Mobile’s Terms shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement. &nbsp;Except as expressly and specifically set forth in this these Terms, no representations, statements, consents, waivers, or other acts or omissions by KSG Mobile shall be deemed a modification of these Terms nor be legally binding, unless documented in physical writing, hand signed by you and a duly appointed Officer or Director of KSG Mobile.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Injunctive Relief</p>

          <p class="mb-2">You acknowledge that the rights granted and obligations made under the KSG Mobile’s Terms to KSG Mobile are of a unique and irreplaceable nature, the loss of which shall irreparably harm KSG Mobile and which cannot be replaced by monetary damages alone. &nbsp;Accordingly, KSG Mobile shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you. &nbsp;You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any KSG Mobile game, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Headings</p>

          <p class="mb-2">The headings titles in these Terms are provided solely for convenience and have no legal or contractual significance.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Severability</p>

          <p class="mb-2">If any portion of these Terms is deemed void or unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of the remaining provisions.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Force Majeure</p>

          <p class="mb-2">KSG Mobile will not be liable in any amount for failure to perform any obligation under these Terms if such failure is caused by the occurrence of any unforeseen contingency beyond KSG Mobile’s reasonable control, including, without limitation, acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
        </li>

        <li>
          <p class="text-lg mb-2">Notices</p>

          <p class="mb-2">We may notify you via postings on KSG Mobile web properties, and via e-mail or any other contact information you provide to us.</p>

          <p class="mb-2">All notices given by you or required from you under the KSG Mobile’s Terms shall be in writing and addressed to:</p>

          <p class="mb-2">
            KSG Mobile, Inc.<br>
            Attn: Legal Department,<br>
            10275 Wayzata Boulevard, Suite 300,<br>
            Minnetonka, MN 55305<br>
            USA
          </p>

          <p class="mb-2">Any notices that you provide without compliance with this Section on Notices shall have no legal effect. &nbsp;If you have any questions regarding the KSG Mobile’s Terms you may contact us at <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline">support@ksgmobileinc.com</a></p>

          <!-- <br>
          <p class="mb-2"><strong>Last updated: </strong>September 12, 2018</p> -->
        </li>
      </ol>
    </fieldset>
  </template>
  <template v-slot:footer>
    <div class="mt-4 flex flex-row-reverse">
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 dark:bg-green-800 text-base font-medium text-white dark:text-gray-100 focus:outline-none ml-3 sm:w-auto sm:text-sm"
        @click="closeModal"
      >
        OK
      </button>
    </div>
  </template>
</base-modal>
</template>

<script>
import BaseModal from './ui/BaseModal.vue'
export default {
  props: ['show-terms', 'modal-device'],
  emits: ['close-modal', 'privacy-modal', 'responsible-modal'],
  components: {
    BaseModal
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    },
    privacyModal () {
      this.$emit('privacy-modal')
    },
    responsibleModal () {
      this.$emit('responsible-modal')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
