import { createStore } from 'vuex'

export default createStore({
  state: {
    privacy: false,
    terms: false,
    responsibleGaming: false,
    params: '',
    optOut: false
  },
  getters: {
    getPrivacy (state) {
      return state.privacy
    },
    getTerms (state) {
      return state.terms
    },
    getParams (state) {
      return state.params
    },
    getOptOut (state) {
      return state.optOut
    },
    getResponsibleGaming (state) {
      return state.responsibleGaming
    }
  },
  mutations: {
    setPrivacy (state, payload) {
      state.privacy = payload
    },
    setTerms (state, payload) {
      state.terms = payload
    },
    setParams (state, payload) {
      state.params = payload
    },
    setOptOut (state, payload) {
      state.optOut = payload
    },
    setResponsibleGaming (state, payload) {
      state.responsibleGaming = payload
    }
  },
  actions: {
    changePrivacy (context, payload) {
      context.commit('setPrivacy', payload)
    },
    changeTerms (context, payload) {
      context.commit('setTerms', payload)
    },
    changeParams (context, payload) {
      context.commit('setParams', payload)
    },
    changeOptOut (context, payload) {
      context.commit('setOptOut', payload)
    },
    changeResponsibleGaming (context, payload) {
      context.commit('setResponsibleGaming', payload)
    }
  },
  modules: {
  }
})
