<template lang="html">
  <div id="background">
    <div id="container">
      <img id="logo" width="1056" height="828" src="../assets/redirect/img/logo.png" />
      <div id="button" @click="openFallbackUrl"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openFallbackUrl () {
      const params = this.$store.getters.getParams
      const fallbackUrl = 'https://showmevegasslots.onelink.me/uBTP/' + params
      window.location = fallbackUrl
    }
  },
  mounted () {
    // var params = window.location.search
    const params = this.$store.getters.getParams
    const appUrl = 'com.ksgmobileinc.showmevegasslotsv2://Blaster' + params
    const fallbackUrl = 'https://showmevegasslots.onelink.me/uBTP/' + params
    let fallbackTimeout
    // var testUrl = 'https://www.showmevegasslots.com/redirect?promoLink=ZF1UDhA6DDDl9n8G&utm_source=ShowMeVegasSlots&utm_medium=Fanpage&utm_campaign=Promo,OpenToAll_AllGames,Game1-5&utm_content=Image,FanPage_Promo_OpenToAll_AllGames_v01&utm_term=20190419,G7,V0,R0,'

    function openFallbackUrl () {
      window.location = fallbackUrl
    }

    function stopFallbackUrl () {
      clearTimeout(fallbackTimeout)
      fallbackTimeout = null
      window.removeEventListener('pagehide', stopFallbackUrl)
    }

    if (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase())) {
      window.location.href = appUrl
      fallbackTimeout = window.setTimeout(openFallbackUrl, 500)
      window.addEventListener('pagehide', stopFallbackUrl)
    } else if (/android/i.test(navigator.userAgent.toLowerCase())) {
      window.location.href = appUrl
      fallbackTimeout = window.setTimeout(openFallbackUrl, 500)
      window.addEventListener('pagehide', stopFallbackUrl)
    } else {
      openFallbackUrl()
    }
  }
}
</script>

<style lang="css">
#background {
  background: url("../assets/redirect/img/background.png") 50% 50% no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  /* top: 0;
  left: 0; */
  left: 0;
}

#container {
  width: 100%;
  max-width: 900px;
  min-width: 600px;
  position: absolute;
  /* top: 25%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

#button {
  background: url('../assets/redirect/img/install.png') no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  max-width: 220px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
}

#button:hover {
  background: url('../assets/redirect/img/install_over.png') no-repeat;
  background-size: contain;
}
</style>
