<template>
  <div class="bg-cover bg-center bg-no-repeat" :class="mobile ? landscape ? 'w-full h-full' : 'w-screen h-screen' : 'w-screen h-screen desktopHeight home'" :style="{ backgroundImage: 'url(' + require('@/assets/Background.png') + ')' }">
    <router-view/>
  </div>
</template>

<script>
export default {
  computed: {
    mobile () {
      return this.$screen.touch
    },
    landscape () {
      return this.$screen.landscape
    }
  },
  watch: {
    mobile () {
      // console.log(this.mobile)
      this.$forceUpdate()
    }
  },
  created () {
    // const orientation = window.screen.orientation.type
    // if (orientation === 'portrait-primary') {
    //   console.log(orientation)
    // } else if (orientation === 'landscape-primary') {
    //   console.log(orientation)
    //   screen.orientation.lock('portrait-primary')
    // }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

.home {
  min-width: 360px;
  min-height: 768px;
}

.desktopHeight {
  // min-height: 999px;
}

// @media screen and (min-aspect-ratio: 16/9) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
//   .home {
//     height: inherit;
//   }
// }
</style>
