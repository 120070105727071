<template>
<TransitionRoot appear :show="showModal" as="template">
  <Dialog as="div" static :open="showModal" @close="closeModal">
    <div class="fixed inset-0 z-10 overflow-y-auto" id="modalId">
      <div class="min-h-screen px-4 text-center">
        <!-- <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
        </TransitionChild> -->
        <DialogOverlay class="fixed inset-0 bg-black opacity-50" />

        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 text-gray-200 shadow-xl rounded-2xl"
            :class="modalSize ? 'max-w-xl' : 'max-w-5xl'"
          >
            <!-- <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"> -->
            <div class="absolute top-0 right-0 pt-4 pr-4">
              <button type="button" class="bg-white dark:bg-gray-700 rounded-md text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 focus:outline-none" @click="closeModal">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <DialogTitle
              as="h3"
              class="text-lg font-bold leading-6"
            >
              <slot name="title"></slot>
            </DialogTitle>
            <hr>
            <div class="mt-2 overflow-y-auto h-3/5">
              <slot name="body"></slot>
            </div>

            <slot name="footer"></slot>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default {
  props: ['show-modal', 'modal-size'],
  emits: ['close-modal'],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    XIcon
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    }
  },
  mounted () {
    setTimeout(() => {
      document.querySelector('#modalId').scrollTo(0, 0)
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
</style>
