<template lang="html">
<base-modal :show-modal="showResponsible" :modal-size="modalDevice" @close-modal="closeModal">
    <template v-slot:title>
        Responsible Gaming
    </template>
    <template v-slot:body>
    <fieldset class="p-2 space-y-4">
        <p class="text-lg">Date Last Modified: October 31, 2022</p>

        <h3 class="text-lg">What is Responsible Gameplay?</h3>
        <hr>

        <p>There are a wide variety of reasons why people enjoy playing video games, including to have fun, pass the time, and encourage relaxation. &nbsp;Regardless of your reason for playing, we want to ensure we’re providing you with a safe, enjoyable experience. &nbsp;That includes a place to play responsibly, set limits or take a break. &nbsp;Playing video games can be a fun part of a balanced life, but we recognize that there can be too much of a good thing.</p>

        <p>While we offer social casino-style games, these games are not gambling, and do not offer the opportunity to win real money or prizes. &nbsp;Practice or success at social casino-style games does not imply future success at gambling. &nbsp;You must be 18 or older to access and play our social casino-style games.</p>

        <h3 class="text-lg">When Might Gameplay be a Problem?</h3>
        <hr>

        <p>If you, a friend or a family member has concerns about your relationship with video games, please take a moment to ask yourself the below questions. &nbsp;While this list of questions is not meant to be comprehensive, or serve as a medical or psychological diagnostic test, answering yes to one or more of the following questions may help you identify the need to set limits, take a break from video games, and/or seek help from a trusted professional.</p>

        <ul class="list-disc list-outside ml-4 grid gap-2">
            <li>Is your gameplay interfering with your work?</li>
            <li>Is your gameplay negatively impacting your home life?</li>
            <li>Have you lost interest in hobbies and activities you previously enjoyed, preferring to play video games instead?</li>
            <li>Do you find it difficult to limit the amount of time you spend playing video games?</li>
            <li>Have you ever felt sad or remorseful after playing a video game?</li>
            <li>Does playing video games decrease your ambition or efficiency?</li>
            <li>Do you ever spend more money on video games than you can afford?</li>
            <li>Do you lie about or hide how much time or money you spend playing video games?</li>
            <li>Do your real life problems and challenges create an urge to play video games?</li>
            <li>Have you ever considered harming yourself as a result of your gameplay?</li>
        </ul>

        <h3 class="text-lg">Steps You Can Take If You Need a Break</h3>
        <hr>

        <p>It’s important to keep your health and happiness in mind. &nbsp;If you have concerns about the amount of time spent on our games, mobile apps or internet sites, we encourage you to contact our <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline cursor-pointer">Customer Support team</a>.</p>

        <p>Monitoring the amount of time you spend playing video games is a good first step to better understanding how gameplay may be affecting your life and wellbeing. &nbsp;Many mobile devices come with built-in functionality to track the time you spend interacting with that device. For example:</p>
        <ul class="list-disc list-outside ml-4 grid gap-2">
            <li>For instructions on tracking the time you spend on your Apple device, please see this link: <br><a href="https://support.apple.com/en-us/HT208982" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.apple.com/en-us/HT208982</a></li>
            <li>For instructions on tracking the time you spend on your Android device, please see this link: <br><a href="https://support.google.com/android/answer/9346420?hl=en" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.google.com/android/answer/9346420?hl=en</a></li>
        </ul>

        <p>If your device isn’t listed above, please contact our <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline cursor-pointer">Customer Support team</a>  for further assistance.</p>

        <h3 class="text-lg">Disabling Game Notifications</h3>
        <hr>

        <p>You may also want to remove game notifications that appear on your smartphone or tablet. &nbsp;Please see the information in the links below for your device or platform:</p>
        <ul class="list-disc list-outside ml-4 grid gap-2">
            <li>For instructions on turning off game notifications on your Apple device, please see this link: <br><a href="https://support.apple.com/en-gb/HT201925" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.apple.com/en-gb/HT201925</a></li>
            <li>For instructions on turning off game notifications on your Android device, please see this link:  <br><a href="https://support.google.com/chrome/answer/3220216?hl=en" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.google.com/chrome/answer/3220216?hl=en</a></li>
            <li>For instructions on turning off game notifications on your Facebook apps or games, please see this link:   <br><a href="https://www.facebook.com/help/476337625740088?sr=2&query=game%20notification&sid=2EatnwYez7VOdzl01" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.facebook.com/help/476337625740088?sr=2&query=game%20notification&sid=2EatnwYez7VOdzl01</a></li>
        </ul>

        <h3 class="text-lg">Third-Party Blocking Software</h3>
        <hr>

        <p>Third-party blocking software allows you to block access to certain apps, websites or other internet services and/or to set access permissions. &nbsp;Some blocking software is free, while other blocking software includes a charge. &nbsp;If you search online, you will find there are a number of options to choose from. &nbsp;For smartphones and tablets, you can also search on the Apple App store or Google Play to find available products. &nbsp;Please note that KSG Mobile is not able to recommend or endorse the use of a particular third-party block software.</p>

        <h3 class="text-lg">Disabling Your KSG Mobile Game Account</h3>
        <hr>

        <p>Additionally, KSG Mobile can disable your account and make it permanently inaccessible. Please contact our <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline cursor-pointer">Customer Support team</a>  if you are interested in permanently disabling your account.</p>

        <h3 class="text-lg">Steps You Can Take to Limit the Money You Spend</h3>
        <hr>

        <p><strong>Disabling or Restricting In-App Purchases</strong></p>

        <p>KSG Mobile social casino-style games are free to play and in-app purchases are available. &nbsp;Although purchasing is always an option, it is possible to disable, limit, or restrict in-app purchases via your mobile device settings.</p>
        <ul class="list-disc list-outside ml-4 grid gap-2">
            <li>For instructions on preventing in-app purchases on your Apple device, please see this link: <br><a href="https://support.apple.com/en-gb/HT204396" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.apple.com/en-gb/HT204396</a></li>
            <li>For instructions on requiring a password or authentication before an in-app purchase is made on your Android device please see this link:  <br><a href="https://support.google.com/googleplay/answer/1626831?hl=en" target="_blank" class="text-blue-500 underline cursor-pointer">https://support.google.com/googleplay/answer/1626831?hl=en</a></li>
            <li>For instructions on turning off in-app purchases on your Amazon device, please see this link:   <br><a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GM5UP39EFNETVXSE" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.amazon.com/gp/help/customer/display.html?nodeId=GM5UP39EFNETVXSE</a></li>
            <li>For instructions on blocking an app or game on Facebook, please see this link   <br><a href="https://www.facebook.com/help/1727608884153160" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.facebook.com/help/1727608884153160</a></li>
            <li>For instructions on removing an app or game on Facebook, please see this link   <br><a href="https://www.facebook.com/help/942196655898243/?helpref=hc_fnav" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.facebook.com/help/942196655898243/?helpref=hc_fnav</a></li>
        </ul>

        <p><strong>Disabling Your KSG Mobile Game Account</strong></p>

        <p>Additionally, KSG Mobile can disable your account and make it permanently inaccessible. &nbsp;Please contact our <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline cursor-pointer">Customer Support team</a> if you have concerns about the amount of money you are spending on our games, and are interested in permanently disabling your account.</p>

        <h3 class="text-lg">If You Need More Help</h3>
        <hr>

        <p>Below is a list of additional resources that you might find helpful if you’re interested in expanding your knowledge about responsible gameplay, or feel you may need more help in managing your own relationship with video games.</p>

        <p>Please note that these links are being provided for your convenience only, and not as an endorsement or recommendation of such websites. &nbsp;KSG Mobile does not control or provide any content or information at these websites.</p>

        <ul class="list-disc list-outside ml-4 grid gap-2">
            <li><strong>Smart Social Gamers</strong>, an online resource that provides guidance, tips and expert advice for everyone to have a positive social games experience: <br><a href="http://smartsocialgamers.org/" target="_blank" class="text-blue-500 underline cursor-pointer">http://smartsocialgamers.org/</a></li>
            <li><strong>CheckPoint</strong>, a charity that provides mental health information and resources for gamers around the globe: <br><a href="https://checkpointorg.com/about-page/" target="_blank" class="text-blue-500 underline cursor-pointer">https://checkpointorg.com/about-page/</a></li>
            <li><strong>National Institute of Mental Health</strong>, for help in locating mental health professions in the United States: <br><a href="https://www.nimh.nih.gov/health/find-help/index.shtml#part_150430" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.nimh.nih.gov/health/find-help/index.shtml#part_150430</a></li>
            <li><strong>healthdirect</strong>, for help in locating mental health professionals in Australia: <br><a href="https://www.healthdirect.gov.au/australian-health-services" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.healthdirect.gov.au/australian-health-services</a></li>
        </ul>

        <p><strong>IMPORTANT:</strong><br>
            If you, a friend or a loved one are having thoughts of self-harm, please seek IMMEDIATE professional help. A list of suicide prevention centers around the world can be found here: <a href="https://www.iasp.info/resources/Crisis_Centres/" target="_blank" class="text-blue-500 underline cursor-pointer">https://www.iasp.info/resources/Crisis_Centres/</a>
        </p>

        <!-- California privacy rights -->
        <h3 class="text-lg">Self-Exclusion Policy</h3>
        <hr>

        <p>Playing video games can be a fun part of a balanced life, but KSG Mobile recognizes that there can be too much of a good thing. &nbsp;We want to ensure we’re providing you with a safe, enjoyable experience; that includes empowering you with the ability to permanently exclude yourself from our games. &nbsp;This Self-Exclusion Policy explains how you can implement this type of change, and how this change will impact your ability to access KSG Mobile games in the future.</p>

        <p>Please read this policy carefully. If you have questions after reading this policy, please reach out to our <a href="mailto:support@ksgmobileinc.com" target="_top" class="text-blue-500 underline cursor-pointer">Customer Support team</a> for assistance.</p>

        <p>Regardless of your reason — for example, you may be struggling to exercise control over your gameplay, your gameplay may be having a negative effect on your life, or you simply no longer wish to have a KSG Mobile account — you can contact our Customer Service team at any time to request that your account be banned. &nbsp;The Customer Service team will promptly action your request, no questions asked. Once implemented, your access to the app will be blocked.</p>

        <p>Please note that all account bans are permanent, regardless of whether you proactively requested that your account be banned, or a ban was imposed on your account at the discretion of KSG Mobile. There are no exceptions to this permanent ban status.</p>

        <p>Once a ban has been implemented, KSG Mobile will continue to take reasonable steps in the future, in accordance with our policies, to ban any new accounts you create if and when we are able to connect those new accounts to you.</p>

        <p>To set temporary limits on your gameplay and/or the money you spend, please see Limit the Money You Spend section above for more information.</p>
    </fieldset>
    </template>
    <template v-slot:footer>
    <div class="mt-4 flex flex-row-reverse">
        <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 dark:bg-green-800 text-base font-medium text-white dark:text-gray-100 focus:outline-none ml-3 sm:w-auto sm:text-sm"
        @click="closeModal"
        >
        OK
        </button>
        <!-- <button
        type="button"
        class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        @click="closeModal"
        >
        Cancel
        </button> -->
    </div>
    </template>
</base-modal>
</template>

<script>
import BaseModal from './ui/BaseModal.vue'
export default {
  props: ['show-responsible', 'modal-device'],
  emits: ['close-modal'],
  components: {
    BaseModal
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    }
    // termsModal () {
    //   this.$emit('terms-modal')
    // }
  }
}
</script>

<style lang="css" scoped>
</style>
